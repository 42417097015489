import {
    Create,
    SimpleForm,
    TextInput,
    required,
    PasswordInput,
} from 'react-admin';

function SemaCreate() {
    return (
        <Create>
            <SimpleForm>
                <TextInput source="username" validate={required()} />
                <PasswordInput source="password" validate={required()} />
            </SimpleForm>
        </Create>
    );
}

export default SemaCreate;
