import {
    List,
    TopToolbar,
    CreateButton,
    Datagrid,
    TextField,
    Identifier,
    RaRecord,
    Button,
    useRecordContext
} from 'react-admin';

const handleClick = (id: Identifier, resource: string, record: RaRecord) => {
    if (typeof navigator !== 'undefined' && navigator.clipboard) {
        try {
            navigator.clipboard.writeText(record.token);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    } else {
        alert('Failed to copy, please copy manually: ' + record.token);
        console.error('Clipboard API not available');
    }
    return false as const;
}

const CopyButton = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <Button label='Copy' onClick={(e) => handleClick(record.id, 'tokens', record)} />;
};

function TokenList() {
    return (
        <List actions={<TopToolbar><CreateButton /></TopToolbar>} pagination={false} empty={false}>
            <Datagrid rowClick={false} >
                <TextField source="token" />
                <CopyButton />
            </Datagrid>
        </List>
    );
}

export default TokenList;