import { Button, useDataProvider, useRecordContext } from "react-admin";
import { useMutation } from "@tanstack/react-query";

export const PushButton = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const mutation = useMutation({
        mutationFn: () => dataProvider.bigcommercePush(record.id)
    });
    return <Button label="Push" onClick={(e) => { e.stopPropagation(); mutation.mutate()}} disabled={mutation.isPending} />;
}