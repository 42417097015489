import { Button, useDataProvider, useRecordContext } from "react-admin";
import { useMutation } from "@tanstack/react-query";

export const FitmentButton = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();

    const { isPending, mutate } = useMutation({mutationFn: () => dataProvider.downloadFitment(record.id)});

    if (record.downloaded) {
        return (
            <Button 
                label="Download Fitment" 
                onClick={(e) => { 
                    e.stopPropagation(); 
                    mutate();
                }} 
                disabled={isPending} // Disable if loading or disabled
            />
        );
    } else{
        return <Button 
        label="Download Fitment" 
        disabled={true}
    />; 
    }
}
