import {
    CreateButton,
    DatagridConfigurable,
    ExportButton,
    List,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TopToolbar,
    BulkExportButton,
    FilterButton,
    ReferenceField,
    TextInput,
    ReferenceArrayInput,
    BulkDeleteWithUndoButton,
    SelectArrayInput,
    ArrayInput,
    ReferenceManyField,
    SingleFieldList,
    ReferenceArrayField
} from 'react-admin';
import { ImportButton, ImportConfig } from "react-admin-import-csv";
import { Fragment } from 'react';
import { Empty } from './empty';




const prodFilters = [
    <SearchInput source="q" alwaysOn key="q" />,
    <TextInput source="title" label="Title" />,
    <TextInput source="sku" label="SKU" />,
    <ReferenceArrayInput source="brand_id" reference="new-brands" label="Brand" key="brand_id" />,
    <TextInput source="categories.name" label="Category" />,
    <ReferenceArrayInput source="tags" reference="tags" label="Tags" key="tags" />,
]

// const exporter: Exporter = (products: any[]) => {
//     const productsForExport = products.map((product: any) => {
//         productsForExport.sku = product.sku
//         productsForExport.title: product.title,
//         productsForExport.short_description: product.short_description,
//         productsForExport.long_description: product.long_description,
//         productsForExport.brand_id: product.brand_id,
//         productsForExport.part_type: product.part_type,
//         productsForExport.pricing: product.pricing,
//         productsForExport.shipping: product.shipping,
//         productsForExport.images: product.images,
//         productsForExport.media: product.media,
//         productsForExport.custom_farts: product.custom_fields,
//     });
//     jsonExport(productsForExport, (err: any, csv: any) => {downloadCSV(csv, 'products')});
// };

const iconfig: ImportConfig = {
    transformRows(csvRows) {
        return Promise.resolve(csvRows.map(row => {
            return {
                sku: row.sku,
                title: row.title,
                short_description: row.short_description,
                long_description: row.long_description,
                brand_id: row.brand_id,
                part_type: row.part_type,
                pricing: [row.pricing],
                shipping: [row.shipping],
                images: [row.images],
                media: [row.media],
                custom_fields: [row.custom_fields],

            };
        }));
    },
}

const econfig: any = {
    importConfig: iconfig,
}

const ProductBulkActionButtons = (props: any) => (
    <Fragment>
        <BulkExportButton {...props} />
        <BulkDeleteWithUndoButton {...props} />
        {/* <BulkUpdateWithConfirmButton {...props} /> */}
    </Fragment>
);


function NProductList() {
    return (
        <List
            filters={prodFilters}
            actions={
                <TopToolbar>
                    <SelectColumnsButton />
                    <FilterButton />
                    <ExportButton />
                    <CreateButton />
                    <ImportButton {...iconfig} />
                </TopToolbar>
            }
            empty={<Empty {...econfig} />}
        >
            <DatagridConfigurable rowClick="edit" bulkActionButtons={<ProductBulkActionButtons />}>
                <ReferenceField label="Brand" source="brand_id" reference="new-brands" link="show">
                    <TextField source="brand_name" />
                </ReferenceField>
                <TextField source="sku" label="SKU" />
                <TextField source="title" />
                <TextField source="short_description" sx={{ textOverflow: 'ellipsis' }} />
                <ReferenceArrayField source="tags" reference="tags">
                    <SingleFieldList>
                        <TextField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
            </DatagridConfigurable>
        </List>
    );
}

export default NProductList;
