import React, { Fragment } from 'react';
import { useState, useCallback } from 'react';
import {
    IconButtonWithTooltip,
    InfiniteList,
    List,
    SearchInput,
    TopToolbar,
    useListContext,
    useDataProvider,
    SimpleShowLayout,
    ShowBase,
    useNotify,
    useRefresh,
} from 'react-admin';
import {
    Accordion,
    AccordionSummary, 
    AccordionDetails, 
    Typography, 
    Grid, 
    Box, 
    Button, 
    IconButton, 
    ButtonGroup, 
    Container,
    Tab,
    Tabs,
    Divider,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ProductShowContent } from '../nproducts/ShowForm';

function capitalizeWords(str) {
    if (typeof str === 'number') {
        str = str.toString();
    }
    return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

function pathOne(path) {
    let p = JSON.parse(path);
    return capitalizeWords(p[0]);
}

function formatPath(path) {
    let p = JSON.parse(path);
    if (p.length === 1) {
        return capitalizeWords(p[0]);
    }
    else if (p.length === 2) {
        return `${capitalizeWords(p[0])}`;
    }
    else if (p.length === 3) {
        return `${capitalizeWords(p[0])} - ${capitalizeWords(p[2])}`;
    }
    return JSON.parse(path).map((p) => capitalizeWords(p)).join(' > ');
}

function formatPrice(price) {
    if (price === "") { return ""; }
    let p = JSON.parse(price);
    let unit = p.price_uom ? p.price_uom : 'No unit';
    return `$${p.price} (${unit}) ${p.price_type}`;
}

function truncateURL(url) {
    let parts = url.split('/');
    let domain = parts[2];
    let filename = parts[parts.length - 1];
    if (filename.includes('",')) {
        filename = filename.split('",')[0];
    }
    return `${domain}/.../${filename}`;
}

function formatShipping(shipping) {
    if (shipping === "") { return ""; }
    let s = JSON.parse(shipping);
    let weight_unit = s.weight_uom ? s.weight_uom : '(No unit)';
    let dimension_unit = s.dimension_uom ? s.dimension_uom : '(No unit)';
    return `${s.weight} ${weight_unit} ${s.height}x${s.width}x${s.length} ${dimension_unit}`;
}

function formatDiff(record) {
    let action = record.action;
    let path = record.path ? JSON.parse(record.path) : [];
    let value = record.value ? record.value : '';
    let old_value = record.old_value ? record.old_value : '';
    if (old_value === 'null') { old_value = ''; }
    if (old_value === 'unknown___') { old_value = ''; }
    if (value === 'null') { value = ''; }
    if (value === 'unknown___') { value = ''; }
    if (path[0] === "pricing") { value = formatPrice(value); old_value = formatPrice(old_value); }
    if (path[0] === "images" && path[2] === "url") { value = truncateURL(value); old_value = truncateURL(old_value); }
    if (path[0] === "images" && path.length === 2) { value = truncateURL(value); old_value = truncateURL(old_value); }
    if (path[0] === "media" && path[2] === "url") { value = truncateURL(value); old_value = truncateURL(old_value); }
    if (path[0] === "media" && path.length === 2) { value = truncateURL(value); old_value = truncateURL(old_value); }
    if (path[0] === "shipping" && path.length === 2) { value = formatShipping(value); old_value = formatShipping(old_value); }
    if (action === 'values_changed' || action === 'type_changes') {
        if (old_value && value) { return { old: old_value, new: value }; }
        else if (value) { return { old: '', new: value }; }
        else if (old_value) { return { old: old_value, new: '' }; }
    } else if (action === 'unordered_iterable_item_added') {
        return { old: '', new: value };
    } else if (action === 'unordered_iterable_item_removed') {
        return { old: value, new: '' };
    } else {
        return { old: old_value, new: value };
    }
}

type colors = 'primary' | 'error' | 'secondary' | 'disabled' | 'textSecondary' | 'info';

// const handleClick = (record: any, action: string) => {
//     const { dataProvider } = useDataProvider();

// }

const ConflictItem = ({ record }) => {
    const refresh = useRefresh();
    const notify = useNotify();
    let buttonsDisabled: boolean;
    let acceptIconColor: colors;
    let acceptTextColor: colors;
    let rejectIconColor: colors;
    let rejectTextColor: colors;
    let pathTextColor: colors;
    let rejectPathIconColor: colors;
    let acceptPathIconColor: colors;
    let rejectIconVis: any;
    let acceptIconVis: any;
    let pathIconVis: any;

    const defaultColors = () => {
        buttonsDisabled = Boolean(record.resolution !== 'unresolved');
        acceptIconColor = 'primary';
        acceptTextColor = 'primary';
        rejectIconColor = 'error';
        rejectTextColor = 'error';
        pathTextColor = 'textSecondary';
        rejectPathIconColor = 'error';
        acceptPathIconColor = 'primary';
        rejectIconVis = {
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0s, opacity 0.4s ease-in-out',
        }
        acceptIconVis = {
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0s, opacity 0.4s ease-in-out',
        }
        pathIconVis = {
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0s, opacity 0.4s ease-in-out',
        }
    }

    const acceptColors = () => {
        buttonsDisabled = true;
        rejectTextColor = 'disabled';
        rejectIconColor = 'disabled';
        if (record.resolution === "path_accept") {
            pathTextColor = 'primary';
            rejectPathIconColor = 'disabled';
            acceptPathIconColor = 'primary';
        } else {
            rejectPathIconColor = 'disabled';
            acceptPathIconColor = 'disabled';
        }
        acceptIconVis = {
            visibility: 'visible',
            opacity: 1,
            transition: 'visibility 0s, opacity 0.4s ease-in-out',
        }
        rejectIconVis = {
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0s, opacity 0.4s ease-in-out',
        }
        pathIconVis = {
            visibility: 'visible',
            opacity: 1,
            transition: 'visibility 0s, opacity 0.4s ease-in-out',
        }
    }

    const rejectColors = () => {
        buttonsDisabled = true;
        acceptTextColor = 'disabled';
        acceptIconColor = 'disabled';
        acceptPathIconColor = 'disabled';
        if (record.resolution === "path_reject") {
            pathTextColor = 'error';
            rejectPathIconColor = 'error';
        } else {
            rejectPathIconColor = 'disabled';
        }
        rejectIconVis = {
            visibility: 'visible',
            opacity: 1,
            transition: 'visibility 0s, opacity 0.4s ease-in-out',
        }
        acceptIconVis = {
            visibility: 'hidden',
            opacity: 0,
            transition: 'visibility 0s, opacity 0.4s ease-in-out',
        }
        pathIconVis = {
            visibility: 'visible',
            opacity: 1,
            transition: 'visibility 0s, opacity 0.4s ease-in-out',
        }
    }


    const dataProvider = useDataProvider();

    const diff = formatDiff(record);

    const handleAcceptPath = (id, path) => {
        console.log('Accepting all for product', id, 'path', path);
        dataProvider.productUpdate(id, 'accept_path');
        notify('Accepted all updates for ' + pathOne(path));
        refresh();
    }

    const handleRejectPath = (id, path) => {
        dataProvider.productUpdate(id, 'reject_path');
        notify('Rejected all updates for ' + pathOne(path));
        refresh();
    }

    const handleAccept = (id) => {
        dataProvider.productUpdate(id, 'accept');

        notify('Accepted update');
        refresh();
    }

    const handleReject = (id) => {
        dataProvider.productUpdate(id, 'reject');
        notify('Rejected update');
        refresh();
    }
    


    defaultColors();

    if (record.resolution === "accept" || record.resolution === "accept_product" || record.resolution === "accept_path") {
        acceptColors();
    }
    if (record.resolution === "reject" || record.resolution === "reject_path" || record.resolution === "reject_product") {
        rejectColors();
    }


    return (
        <Grid container>
            <Grid item xs={2}>
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="body2" color={pathTextColor}>{formatPath(record.path)}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <ButtonGroup disabled={buttonsDisabled} variant="text" sx={{ margin: 0, padding: 0 }}>
                            <IconButtonWithTooltip disabled={buttonsDisabled} onClick={() => handleRejectPath(record.id, record.path)} sx={{ margin: 0, padding: 0, marginRight: 0.5 }} label={`Reject all ${pathOne(record.path)} updates`}>
                                <RemoveCircleOutlineIcon color={rejectPathIconColor} />
                            </IconButtonWithTooltip>
                            <IconButtonWithTooltip disabled={buttonsDisabled} onClick={() => handleAcceptPath(record.id, record.path)} label={`Apply all ${pathOne(record.path)} updates`} sx={{ margin: 0, padding: 0 }} >
                                <AddCircleOutlineIcon color={acceptPathIconColor} />
                            </IconButtonWithTooltip>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={5}>
                <Button
                    variant="text"
                    fullWidth
                    startIcon={<RemoveCircleOutlineIcon color={rejectIconColor} />}
                    disabled={buttonsDisabled}
                    onClick={() => handleReject(record.id)}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '& .MuiButton-startIcon': rejectIconVis,
                        '&:hover .MuiButton-startIcon': {
                            visibility: 'visible',
                            opacity: 1,
                        },
                    }}
                >
                    <Typography variant="body2" color={rejectTextColor}>{diff.old}</Typography>
                </Button>
            </Grid>
            <Grid item xs={5}>
                <Button
                    fullWidth
                    endIcon={<AddCircleOutlineIcon color={acceptIconColor} />}
                    variant="text"
                    disabled={buttonsDisabled}
                    onClick={() => handleAccept(record.id)}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '& .MuiButton-endIcon': acceptIconVis,
                        '&:hover .MuiButton-endIcon': {
                            visibility: 'visible',
                            opacity: 1,
                        },
                    }}
                >
                    <Typography variant="body2" color={acceptTextColor}>{diff.new}</Typography>
                </Button>
            </Grid>
        </Grid>
    );
};

const GroupedConflictList = () => {
    const { data, isFetching } = useListContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleAcceptAll = (productId: number) => {
        console.log('Accepting all for product', productId);
        dataProvider.productUpdate(productId, 'accept_product');
        notify('Accepted all updates for product');
        refresh();
    }

    const handleRejectAll = (productId: number) => {
        console.log('Rejecting all for product', productId);
        dataProvider.productUpdate(productId, 'reject_product');
        notify('Rejected all updates for product');
        refresh();
    }

    if (isFetching) return (<Box p={2} justifyContent='center' ><Typography variant='h6' justifyContent="center">Loading...</Typography></Box>);
    if (!data || data.length === 0) return (<Box p={2} justifyContent='center' ><Typography variant='h6' justifyContent="center">No updates found</Typography></Box>);

    const groupedData = data.reduce((acc, record) => {
        if (!acc[record.product_id]) {
            acc[record.product_id] = [];
        }
        acc[record.product_id].push(record);
        return acc;
    }, {});


    Object.keys(groupedData).forEach(productId => {
        groupedData[productId].sort((a: any, b: any) => {
            if (a.action < b.action) return 1;
            if (a.action > b.action) return -1;
            if (a.path < b.path) return -1;
            if (a.path > b.path) return 1;
            return 0;
        });
    });

    const allResolved = (productId: string) => {
        return groupedData[productId].every((record: any) => record.resolution !== "unresolved");
    };

    return (
        <>
            {Object.keys(groupedData).map((product_id) => (
                <Accordion key={product_id} variant='outlined'>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1">Product ID: {product_id}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={2}>
                                <Typography variant="body1" color="info">Path</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" color="info">Current Value</Typography>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography variant="body1" >New Value</Typography>
                            </Grid>
                        </Grid>
                        {groupedData[product_id].map((record, index) => (
                            <ConflictItem key={index} record={record} />
                        ))}
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={2} />
                            <Grid item xs={5}>
                                <Box display="flex" justifyContent="flex-end" mt={2}>
                                    <Button variant={allResolved(product_id) ? "text" : "outlined"} disabled={allResolved(product_id)} color="error" onClick={() => handleRejectAll(product_id)} fullWidth>Reject All for Product</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={5}>
                                <Box display="flex" justifyContent="flex-end" mt={2}>
                                    <Button variant={allResolved(product_id) ? "text" : "outlined"} disabled={allResolved(product_id)} color="primary" onClick={() => handleAcceptAll(product_id)} style={{ marginRight: '5px' }} fullWidth>Accept All for Product</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};

const NewList = () => {
    const { data, isFetching } = useListContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleAccept = (conflictId: number) => {
        console.log('Creating New Product', conflictId);
        dataProvider.productUpdate(conflictId, 'accept_new');
        notify('Created new product');
        refresh();
    }

    const handleReject = (conflictId: number) => {
        console.log('Rejecting New Product', conflictId);
        dataProvider.productUpdate(conflictId, 'reject_new');
        notify('Rejected new product');
    }

    if (isFetching) return (<Box p={2} justifyContent='center' ><Typography variant='h6' justifyContent="center">Loading...</Typography></Box>);

    if (!data || data.length === 0) return (<Box p={2} justifyContent='center' ><Typography variant='h6' justifyContent="center">No new products found</Typography></Box>);

    try {
        JSON.parse(data[0].value).sku;
    } catch (e) {
        return (<Box p={2} justifyContent='center' ><Typography variant='h6' justifyContent="center">No new products found</Typography></Box>);
    }

    return (
        <>
            {data.map((new_prod) => (
                <Accordion key={new_prod.id} variant='outlined'>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1">Product SKU: {JSON.parse(new_prod.value)?.sku}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ProductShowContent key={new_prod.id} record={JSON.parse(new_prod.value)} />
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <Box display="flex" justifyContent="flex-end" mt={2}>
                                    <Button variant={"outlined"} color="error" onClick={() => handleReject(new_prod.id)} fullWidth>Reject New Product</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display="flex" justifyContent="flex-end" mt={2}>
                                    <Button variant={"outlined"} color="primary" onClick={() => handleAccept(new_prod.id)} style={{ marginRight: '5px' }} fullWidth>Accept New Product</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};

const DeletedList = () => {
    const { data, isFetching } = useListContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleAccept = (conflictId: number) => {
        console.log('Deleting Product', conflictId);
        dataProvider.productUpdate(conflictId, 'accept_deleted');
        notify('Deleted product');
        refresh();
    }

    const handleReject = (conflictId: number) => {
        console.log('Not Deleting Product', conflictId);
        dataProvider.productUpdate(conflictId, 'reject_deleted');
        notify('Kept product');
        refresh();
    }
    
    if (isFetching) return (<Box p={2} justifyContent='center' ><Typography variant='h6' justifyContent="center">Loading...</Typography></Box>);

    if (!data || data.length === 0) return (<Box p={2} justifyContent='center' ><Typography variant='h6' justifyContent="center">No deleted products found</Typography></Box>);

    try {
        JSON.parse(data[0].value).sku;
    } catch (e) {
        return (<Box p={2} justifyContent='center' ><Typography variant='h6' justifyContent="center">No deleted products found</Typography></Box>);
    }

    return (
        <>
            {data.map((new_prod) => (
                <Accordion key={JSON.parse(new_prod.value).sku} variant='outlined'>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1">Product SKU: {JSON.parse(new_prod.value).sku}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ProductShowContent record={JSON.parse(new_prod.value)} />
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={6}>
                                <Box display="flex" justifyContent="flex-end" mt={2}>
                                    <Button variant={"outlined"} color="error" onClick={() => handleReject(new_prod.id)} fullWidth>Keep Product</Button>
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Box display="flex" justifyContent="flex-end" mt={2}>
                                    <Button variant={"outlined"} color="primary" onClick={() => handleAccept(new_prod.id)} style={{ marginRight: '5px' }} fullWidth>Delete Product</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
        </>
    );
};



const filters: { [key: string]: { resolution: string | string[] } } = {
    updates: { resolution: "unresolved" },
    new: { resolution: "new" },
    deleted: { resolution: "deleted" },
    resolved: { resolution: ["accept", "reject", "accept_path", "reject_path", "accept_product", "reject_product", "accept_new", "reject_new", "accept_delete", "reject_delete"] },
};

const GroupedConflictTabs = () => {
    const tabs = ["updates", "new", "deleted", "resolved"];
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters } = listContext;
    const [selectedTab, setSelectedTab] = useState('updates');

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: string) => {
            const newFilters = { ...filterValues, ...filters[value] };
            setFilters && setFilters(newFilters, displayedFilters);
            setSelectedTab(value);
        }, [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={selectedTab}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab key={choice} label={<span>{choice[0].toUpperCase() + choice.slice(1)}</span>} value={choice} />
                ))}
            </Tabs>
            <Divider />
            {selectedTab === "updates" ? <GroupedConflictList /> : selectedTab === "resolved"  ? <GroupedConflictList /> : selectedTab === "new" ? <NewList /> : selectedTab === "deleted" ? <DeletedList />: <GroupedConflictList />}
        </Fragment>
    );
}


const ConflictList = () => (
    <List
        filters={[<SearchInput source="q" alwaysOn key="q" />]}
        actions={
            <TopToolbar>
                {/* Add any top toolbar actions you need here */}
            </TopToolbar>
        }
    >
        <GroupedConflictTabs />
    </List>
);

export default ConflictList;
