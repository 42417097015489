import {
    ArrayInput,
    CreateButton,
    DatagridConfigurable,
    List,
    ReferenceField,
    SearchInput,
    SimpleForm,
    SimpleFormIterator,
    FunctionField,
    TextField,
    TopToolbar,
    FormDataConsumer,
    useRecordContext,
    SimpleShowLayout,
    Labeled,
    useDataProvider,
    useNotify,
    NumberField,
    useListContext,
    Count,
    DateField,
} from 'react-admin';

import { Tabs, Divider, Tab } from '@mui/material';
import { UpdateFitmentButton } from './fitmentUpdateButton';
import { UpdateAllFitmentButton } from './fitmentUpdateAllButton';

import groupYears from '../util/GroupYears';

import { ButtonGroup, Button } from "@mui/material";
import { Fragment, useCallback, useState } from 'react';

const FConflictBulkActionButtons = () => (
    <>
        <UpdateFitmentButton />
    </>
)

const ConflictPanel = () => {
    const [selectedTab, setSelectedTab] = useState('updates');
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleClick = (how: string) => {
        const id = record?.id;

        dataProvider.fitmentUpdateAll(id, how)
            .then(() => {
                notify('Applied Updates');
            });
    }

    return (
        <SimpleShowLayout>
            <ReferenceField source="product_id" reference="new-products">
                <TextField source="title" />
            </ReferenceField>
            <SimpleShowLayout direction="row">
                {/* <UpdateAllFitmentButton /> */}
                <ButtonGroup>
                    <Button onClick={() => handleClick("product")}>
                        <ReferenceField source="product_id" reference="new-products" link={false}>
                            <FunctionField source="label" render={record => "Accept all for " + record.sku} />
                        </ReferenceField>
                    </Button>
                    <Button onClick={() => handleClick("reject_product")}>
                        <ReferenceField source="product_id" reference="new-products" link={false}>
                            <FunctionField source="label" render={record => "Reject all for " + record.sku} />
                        </ReferenceField>
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    <Button onClick={() => handleClick("action")}>
                        <FunctionField source="label" render={record => "Accept all for " + record.label} />
                    </Button>
                    <Button onClick={() => handleClick("reject_action")}>
                        <FunctionField source="label" render={record => "Reject all for " + record.label} />
                    </Button>
                </ButtonGroup>
            </SimpleShowLayout>
        </SimpleShowLayout>
    );
}

const FConflictList = () => {
    return (
        <List
            // sort={{ field: 'published_at', order: 'DESC' }}
            filters={[<SearchInput source="q" alwaysOn key="q" />]}
            filterDefaultValues={{ resolution: "unresolved" }}
            actions={
                <TopToolbar>
                    <CreateButton />
                </TopToolbar>
            }
        >
            {/* <DatagridConfigurable bulkActionButtons={<FConflictBulkActionButtons />} expand={<ConflictPanel />} rowClick={'expand'}>
                <ReferenceField source="product_id" reference="new-products">
                    <TextField source="sku" />
                </ReferenceField>
                <TextField source="label" />
                <TextField source="display_value" />
                <TextField source="was" />
            </DatagridConfigurable> */}
            <TabbedDatagrid />
        </List>
    );
}

const tabs = [
    'updates',
    'resolved',
]

const filters: { [key: string]: { resolution: string | string[] } } = {
    updates: { resolution: "unresolved" },
    resolved: { resolution: ["product", "action", "reject_product", "reject_action", "always_product", "always_action"] },
}

const TabbedDatagrid = () => {
    const listContext = useListContext();
    const { filterValues, setFilters, displayedFilters } = listContext;
    const [selectedTab, setSelectedTab] = useState('updates');

    const handleChange = useCallback(
        (event: React.ChangeEvent<{}>, value: string) => {
            const newFilters = { ...filterValues, ...filters[value] };

            // Ensure that null is explicitly set as the filter value
            if (filters[value].resolution === null) {
                newFilters.resolution = null;
            }

            setFilters && setFilters(newFilters, displayedFilters);
            setSelectedTab(value);
        },
        [displayedFilters, filterValues, setFilters]
    );

    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={selectedTab}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab key={choice} label={<span>{choice[0].toUpperCase() + choice.slice(1)} (<Count filter={{ ...filterValues, ...filters[choice] }} sx={{ lineHeight: 'inherit' }} />)</span>} value={choice} />
                ))}
            </Tabs>
            <Divider />
            {selectedTab !== 'updates' ?
                <DatagridConfigurable>
                    <ReferenceField source="product_id" reference="new-products">
                        <TextField source="sku" />
                    </ReferenceField>
                    <TextField source="label" />
                    <TextField source="display_value" />
                    <TextField source="resolution" />
                </DatagridConfigurable>

                :
                <DatagridConfigurable
                    bulkActionButtons={<FConflictBulkActionButtons />} expand={<ConflictPanel />} rowClick={'expand'}
                >
                    <ReferenceField source="product_id" reference="new-products">
                        <TextField source="sku" />
                    </ReferenceField>
                    <TextField source="label" />
                    <TextField source="display_value" />
                    <TextField source="was" />
                </DatagridConfigurable>
            }
        </Fragment>
    );
};

export default FConflictList;