import {
    CreateButton,
    DatagridConfigurable,
    ExportButton,
    FunctionField,
    List,
    ReferenceField,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TopToolbar,
    useDataProvider,
    downloadCSV,
    fetchRelatedRecords,
    FetchRelatedRecords
} from 'react-admin';

import groupYears from '../util/GroupYears';
import jsonExport from 'jsonexport/dist';
import { useEffect, useState } from 'react';

const exporter = async (fitment: any, fetchRelatedRecords: FetchRelatedRecords) => {
    const products = await fetchRelatedRecords(fitment, 'product_id', 'new-products');
    const fitmentForExport = fitment.map((f: any) => {
        const {product_id, submodel, engine, cab_type, bed_length, vehicle_option, notes, label, id, user_id, ...fitForExport} = f
        fitForExport.sku = products[product_id].sku;
        fitForExport.brand_code = products[product_id].brand.aaia_id;
        return fitForExport;
    });

    jsonExport(fitmentForExport, {
        headers: ['brand_code', 'sku', 'years', 'make', 'model', 'drive_type', 'fuel_type', 'num_doors']
    }, (err, csv) => {
        if (err) {
            console.error('Error exporting CSV:', err);
        } else {
            downloadCSV(csv, 'fitment');
        }
    });
}

function FitmentList() {
    return (
        <List
            exporter={exporter}
            // sort={{ field: 'published_at', order: 'DESC' }}
            // filters={[<SearchInput source="q" alwaysOn key="q" />]}
            actions={
                <TopToolbar>
                    <CreateButton />
                    <SelectColumnsButton />
                    <ExportButton />
                </TopToolbar>
            }
        >
            <DatagridConfigurable rowClick="edit">
                <ReferenceField label="Brand" source="product_id" reference="new-products" link={false}>
                    <ReferenceField source="brand_id" reference="new-brands">
                        <TextField source="brand_name" />
                    </ReferenceField>
                </ReferenceField>
                <ReferenceField source="product_id" reference="new-products">
                    <TextField source="title" />
                </ReferenceField>
                {/* <FunctionField label="Years" render={record => record.start_year === record.end_year ? `${record.start_year}` : `${record.start_year} - ${record.end_year}`} /> */}
                <FunctionField label="Years" render={record => groupYears(record.years)} />
                {/* <TextField source="years" /> */}
                <TextField source="make" />
                <TextField source="model" />
                <TextField source="submodel" />
                <TextField source="engine" />
                <TextField source="cab_type" />
                <TextField source="bed_length" />
                <TextField source="num_doors" />
                <TextField source="drive_type" />
                <TextField source="fuel_type" />
                <TextField source="vehicle_options" />
                <TextField source="notes" />
                <TextField source="label" />
            </DatagridConfigurable>
        </List>
    );
}

export default FitmentList;
