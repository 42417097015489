import {
    useListContext,
    useDataProvider,
    useUpdateMany,
    useRefresh,
    useNotify,
    useUnselectAll,
    Button,
} from 'react-admin';
import { Upgrade } from '@mui/icons-material';

export const UpdateFitmentButton = () => {
    const dataProvider = useDataProvider();
    const { selectedIds } = useListContext();
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('fitment-conflicts');
    const handleClick = () => {
        dataProvider.fitmentUpdate(selectedIds)
            .then(() => {
                notify('Views reset');
                refresh();
                unselectAll();
            })
            .catch((error) => {
                notify('Error: views not reset', 'warning');
            });
    }

    return (
        <Button label="Apply Updates" onClick={handleClick}>
            <Upgrade />

        </Button>
    );
};