import { Edit } from 'react-admin';
import { RuleForm } from './form';

function RuleEdit() {
    return (
        <Edit>
            <RuleForm />
        </Edit>
    );
}

export default RuleEdit;
