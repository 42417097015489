import {
    BooleanField,
    CreateButton,
    DatagridConfigurable,
    ExportButton,
    ImageField,
    List,
    ReferenceManyCount,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TopToolbar,
} from 'react-admin';

import  ImageList from './gridList';

function BrandList() {
    return (
        <List
            // sort={{ field: 'published_at', order: 'DESC' }}
            filters={[<SearchInput source="q" alwaysOn key="q" />]}
            actions={
                <TopToolbar>
                    <SelectColumnsButton />
                    <ExportButton />
                    <CreateButton />
                </TopToolbar>
            }
            empty={false}
        >
            <ImageList />
        </List>
    );
}

export default BrandList;
