import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    PasswordInput,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';

function FConflictEdit() {
    return (
        <Edit>
            <SimpleForm>
                <TextInput source="value" validate={required()} />
                <TextInput source="old_value" validate={required()} />
            </SimpleForm>
        </Edit>
    );
}

export default FConflictEdit;
