import {
    ArrayField,
    ArrayInput,
    BooleanInput,
    ChipField,
    Datagrid,
    DateInput,
    Edit,
    ImageField,
    Labeled,
    NumberField,
    NumberInput,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    ReferenceManyCount,
    ReferenceManyField,
    SelectInput,
    SimpleFormIterator,
    SimpleList,
    TabbedForm,
    TextInput,
    required,
    Pagination,
    TextField,
    FunctionField,
    SimpleForm,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Card, CardMedia, Stack, InputAdornment } from '@mui/material';
import { ProductEditContent } from './Form';
import Aside from './Aside';

function NProductEdit() {
    return (
        <Edit>
            <SimpleForm><ProductEditContent /></SimpleForm>
            
            {/* <TabbedForm syncWithLocation={false}>
                <TabbedForm.Tab label="Content">
                    <ProductEditContent />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Shipping" >
                    <ArrayInput source="shipping">
                        <SimpleFormIterator inline>
                            <NumberInput source="length" />
                            <NumberInput source="width" />
                            <NumberInput source="height" />
                            <SelectInput source="dimension_unit" choices={[
                                { id: 'IN', name: 'Inches' },
                                { id: 'CM', name: 'Centimeters'}
                            ]} />
                            <NumberInput source="weight" />
                            <SelectInput source="weight_unit" choices={[
                                { id: 'LB', name: 'Pounds' },
                                { id: 'KG', name: 'Kilograms'}
                            ]} />
                        </SimpleFormIterator>
                    </ArrayInput>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Price" >
                    <ArrayInput source="pricing">
                        <SimpleFormIterator inline>
                            <SelectInput source="price_type" choices={[
                                { id: 'RMP', name: 'RMP' },
                                { id: 'LST', name: 'LST' },
                                { id: 'JBR', name: 'JBR'},
                                { id: 'MAP', name: 'MAP'},
                                { id: 'RET', name: 'RET'},
                            ]} />
                            {/* <TextInput source="price_type" /> 
                            <NumberInput source="price" InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ) }} />
                        </SimpleFormIterator>
                    </ArrayInput>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Categories" >
                    <ArrayInput source="categories">
                        <SimpleFormIterator>
                            <TextInput source="name" fullWidth />
                        </SimpleFormIterator>
                    </ArrayInput>
                </TabbedForm.Tab>
            </TabbedForm> */}
        </Edit>
    );
}

export default NProductEdit;
