import {
    ArrayField,
    ArrayInput,
    BooleanInput,
    ChipField,
    Create,
    Datagrid,
    DateInput,
    Edit,
    ImageField,
    Labeled,
    NumberField,
    NumberInput,
    ReferenceArrayInput,
    ReferenceField,
    ReferenceInput,
    ReferenceManyCount,
    ReferenceManyField,
    SelectInput,
    SimpleFormIterator,
    SimpleList,
    TabbedForm,
    TextInput,
    required,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { Card, CardMedia, Stack, InputAdornment } from '@mui/material';
import { ProductEditContent } from './Form';

function NProductCreate() {
    return (
        <Create>
            <TabbedForm syncWithLocation={false}>
                <TabbedForm.Tab label="Content">
                    <ProductEditContent />
                </TabbedForm.Tab>

                
                <TabbedForm.Tab label="Images">
                    <ArrayInput source="images" label={false}>
                        <SimpleFormIterator inline>
                            <ImageField source='url' />
                            <TextInput source='url' />
                        </SimpleFormIterator>
                    </ArrayInput>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Shipping" >
                    <ArrayInput source="shipping">
                        <SimpleFormIterator inline>
                            <NumberInput source="length" />
                            <NumberInput source="width" />
                            <NumberInput source="height" />
                            <SelectInput source="dimension_unit" choices={[
                                { id: 'IN', name: 'Inches' },
                                { id: 'CM', name: 'Centimeters'}
                            ]} />
                            <NumberInput source="weight" />
                            <SelectInput source="weight_unit" choices={[
                                { id: 'LB', name: 'Pounds' },
                                { id: 'KG', name: 'Kilograms'}
                            ]} />
                        </SimpleFormIterator>
                    </ArrayInput>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Price" >
                    <ArrayInput source="pricing">
                        <SimpleFormIterator inline>
                            <SelectInput source="price_type" choices={[
                                { id: 'RMP', name: 'RMP' },
                                { id: 'CUS', name: 'Custom' },
                                { id: 'JBR', name: 'JBR'},
                                { id: 'MAP', name: 'MAP'},
                                { id: 'MSRP', name: 'MSRP'},
                            ]} />
                            <NumberInput source="price" InputProps={{ startAdornment: ( <InputAdornment position="start">$</InputAdornment> ) }} />
                        </SimpleFormIterator>
                    </ArrayInput>
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Fitment" />
                <TabbedForm.Tab label="Custom Fields">
                    <ArrayInput source="custom_fields">
                        <SimpleFormIterator inline>
                            <TextInput source="name" />
                            <TextInput source="value" />
                        </SimpleFormIterator>
                    </ArrayInput>
                </TabbedForm.Tab>
            </TabbedForm>
        </Create>
    );
}

export default NProductCreate;
