// import List from './List';
import List from './List copy'
import Show from './Show';
import Create from './Create';

export default {
    list: List,
    show: Show,
    create: Create,
    recordRepresentation: "brand_name" 
};