import {
    BooleanInput,
    TextInput,
    SimpleForm,
    Create
} from 'react-admin';

function BrandCreate() {
    return (
        <Create>
            <SimpleForm>
                <TextInput source="aaia_id" label="Brand Code" />
                <TextInput source="brand_name" label="Brand Name" />
            </SimpleForm>
        </Create>
    );
}

export default BrandCreate;
