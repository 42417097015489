import List from './List';
// import Create from './Create';
// import Edit from './Edit';
import { EditGuesser, ListGuesser, RecordRepresentation } from 'react-admin';

export default {
    list: List,
    // create: Create,
    // edit: Edit,
    RecordRepresentation: "type"
};