import { Create } from 'react-admin';
import FitmentForm from './Form';

export default function FitmentCreate() {
    return (
        <Create>
            <FitmentForm />
        </Create>
    );
};
