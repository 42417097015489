import * as React from 'react';
import {
    ArrayInput,
    AutocompleteArrayInput,
    Button,
    Create,
    ReferenceArrayField,
    ReferenceArrayInput,
    ReferenceInput,
    ReferenceManyField,
    required,
    SelectInput,
    SimpleForm,
    SimpleFormIterator,
    SimpleList,
    TextInput,
    useCreate,
    useRecordContext,
    ImageField,
    useInput,
    NumberInput,
    ChipField,
    SingleFieldList,
    useReferenceManyFieldController,
    SaveButton,
    Toolbar,
    useCreateSuggestionContext,
    Form,
    useNotify,
    CreateButton,
} from 'react-admin';
import { Dialog, DialogContent, DialogTitle, DialogActions, Grid, Box, Typography, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, CardMedia, CardActions, IconButton, InputAdornment } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { RichTextInput } from 'ra-input-rich-text';
import groupYears from '../util/GroupYears';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Fragment, Component } from 'react';
import dataProvider from '../dataProvider';
import { Domain } from 'domain';
import { useCallback } from 'react';

const ImageInputWithPreview = ({ source, record, ...rest }) => {
    const { field } = useInput({ source, ...rest });
    const imageUrl = field.value || '';

    return (
        <Card variant="outlined" sx={{ position: 'relative', marginBottom: 2 }}>
            {imageUrl ? (
                <CardMedia
                    component="img"
                    image={imageUrl}
                    title="Uploaded Image"
                    sx={{
                        // Adjust height for larger preview
                        objectFit: 'cover',
                    }}
                />
            ) : (
                <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="body2" color="textSecondary">
                        No Image
                    </Typography>
                </CardContent>
            )}
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <TextInput
                    source={source}
                    placeholder="Image URL"
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{ mt: 1 }}
                />
            </CardActions>
        </Card>
    );
};



const FitmentCreateButton = () => {
    const product = useRecordContext();
    return (<CreateButton resource="fitments" state={{ record: { product_id: product.id } }} />);
};


const BrandInput = (props: any) => {
    const [create] = useCreate();
    return (
        <ReferenceInput {...props} reference="new-brands" sort={{ field: "brand_name", order: "ASC" }} perPage={100}>
            <SelectInput optionText="brand_name" validate={req} fullWidth onCreate={async () => {
                const newBrandName = prompt('Enter the new brand name');
                const newBrandId = prompt('Enter the new brand ID');
                if (newBrandName && newBrandId) {
                    const newBrand = await create('new-brands', { data: { brand_name: newBrandName, aaia_id: newBrandId } });
                    return newBrand.data.id
                }
            }} />
        </ReferenceInput>
    );
}

const TagInput = (props: any) => {
    const [create] = useCreate();
    return (
        <ReferenceArrayInput {...props} reference="tags" source="tags">
            <AutocompleteArrayInput optionText="name" fullWidth onCreate={async () => {
                const tagName = prompt('New Tag Name');
                if (tagName) {
                    const tag = await create('tags', { data: { name: tagName } });
                    return tag.data.id
                }
            }} />
        </ReferenceArrayInput>
    );
}

export const ProductEditContent = () => (
    <Grid container columnSpacing={2}>
        <Grid item xs={12} md={8} sm={8}>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={8}>
                    <TextInput source="title" fullWidth validate={req} />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput source="sku" label="SKU" validate={req} fullWidth />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <BrandInput source="brand_id" label="Brand" fullWidth />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextInput
                        source="part_type"
                        fullWidth
                        validate={req}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TagInput />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextInput source="short_description" validate={req} fullWidth />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <RichTextInput source="long_description" fullWidth />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h5">Images</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <ArrayInput source="images" label={false}>
                                <SimpleFormIterator addButton={<Button startIcon={<AddPhotoAlternateIcon />} variant="contained">Add Image</Button>}>
                                    <ImageInputWithPreview source="url" />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={4} sm={4}>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Fitments</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400, marginBottom: "1%" }}>
                        <ReferenceManyField reference="fitments" target="product_id" source='id' label={false} >
                            <SimpleList
                                primaryText={record => record.make + ' ' + record.model + ' ' + (record.submodel || "")}
                                secondaryText={record => groupYears(record.years)}
                                tertiaryText={record => <ReferenceManyField reference="fitment-conflicts" target="fitment_id" source="id"><SimpleList primaryText={record => record.value} secondaryText={record => record.label} empty={<></>} /></ReferenceManyField>}
                            />
                        </ReferenceManyField>
                    </Box>
                    {/* <Button
                        label="Add Fitment"
                        onClick={() => {
                            alert('Add Fitment');
                        }}
                        fullWidth
                    /> */}
                    <FitmentCreateButton />
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Custom Fields</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400, marginBottom: "1%" }}>
                        <ArrayInput source="custom_fields" label={false}>
                            <SimpleFormIterator inline>
                                <TextInput source="name" />
                                <TextInput source="value" />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Shipping</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400, marginBottom: "1%" }}>
                        <ArrayInput source="shipping" label={false}>
                            <SimpleFormIterator>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <NumberInput source="length" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberInput source="width" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberInput source="height" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectInput source="dimension_unit" choices={[
                                            { id: 'IN', name: 'Inches' },
                                            { id: 'CM', name: 'Centimeters' }
                                        ]} />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <NumberInput source="weight" />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <SelectInput source="weight_unit" choices={[
                                            { id: 'LB', name: 'Pounds' },
                                            { id: 'KG', name: 'Kilograms' }
                                        ]} />
                                    </Grid>
                                </Grid>
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Price</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400, marginBottom: "1%" }}>
                        <ArrayInput source="pricing" label={false}>
                            <SimpleFormIterator inline>
                                <SelectInput source="price_type" choices={[
                                    { id: 'RMP', name: 'RMP' },
                                    { id: 'LST', name: 'LST' },
                                    { id: 'JBR', name: 'JBR' },
                                    { id: 'MAP', name: 'MAP' },
                                    { id: 'RET', name: 'RET' },
                                ]} />
                                <NumberInput source="price" InputProps={{ startAdornment: (<InputAdornment position="start">$</InputAdornment>) }} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>

                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Categories</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400 }}>
                        <ArrayInput source="categories" label={false}>
                            <SimpleFormIterator>
                                <TextInput source="name" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Grid>
    </Grid>
);

const req = [required()];
