import { AppBar, Layout, LayoutProps, Logout, Menu } from 'react-admin';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { JSX } from 'react/jsx-runtime';
import NMenu from './dashboard/Menu';
import { useUserMenu, UserMenu } from 'react-admin';
import { MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import * as React from 'react';
import { Link } from 'react-router-dom';


const SettingsMenuItem = React.forwardRef((props, ref) => {
    const { onClose } = useUserMenu() ?? {};
    return (
        <MenuItem {...props} component={Link} onClick={onClose} to="/settings">
            <ListItemIcon>
                <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
        </MenuItem>
    );
});

const MyAppBar = () => (
    <AppBar userMenu={
        <UserMenu>
            <SettingsMenuItem />
            <Logout />
        </UserMenu>
    }
    />
);

export const QTools = (props: JSX.IntrinsicAttributes & LayoutProps) => (
    <>
        <Layout {...props}  menu={NMenu} appBar={MyAppBar} />
        <ReactQueryDevtools initialIsOpen={false} />
    </>
);