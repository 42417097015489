import {
    CreateButton,
    DatagridConfigurable,
    List,
    SearchInput,
    TextField,
    TopToolbar,
} from 'react-admin';
import { PushButton } from './pushButton';

function SemaList() {
    return (
        <List
            // sort={{ field: 'published_at', order: 'DESC' }}
            filters={[<SearchInput source="q" alwaysOn key="q" />]}
            actions={
                <TopToolbar>
                    <CreateButton />
                </TopToolbar>
            }
        >
            <DatagridConfigurable rowClick="edit">
                <TextField source="name" />
                <PushButton />
            </DatagridConfigurable>
        </List>
    );
}

export default SemaList;
