import { Create } from 'react-admin';
import { RuleForm } from './form';

function RuleCreate() {
    return (
        <Create>
            <RuleForm />
        </Create>
    );
}

export default RuleCreate;
