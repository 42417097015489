import * as React from 'react';
import {
    ArrayField,
    AutocompleteArrayField,
    Button,
    Create,
    ReferenceField,
    required,
    SelectField,
    SimpleList,
    TextField,
    useCreate,
    useRecordContext,
    ImageField,
    NumberField,
    ChipField,
    SingleFieldList,
    useReferenceManyFieldController,
    SaveButton,
    Toolbar,
    useCreateSuggestionContext,
    Form,
    useNotify,
    CreateButton,
    Labeled,
    FunctionField,
} from 'react-admin';
import { Stack, Dialog, DialogContent, DialogTitle, DialogActions, Grid, Box, Typography, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, CardMedia, CardActions, IconButton, FieldAdornment } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import groupYears from '../util/GroupYears';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Fragment, Component } from 'react';
import dataProvider from '../dataProvider';
import { Domain } from 'domain';
import { useCallback } from 'react';

const ImageFieldWithPreview = ({ source, ...rest }: { source: string }) => {
    const record = useRecordContext();
    const field = record[source];
    const imageUrl = field.value || '';

    return (
        <Card variant="outlined" sx={{ position: 'relative', marginBottom: 2 }}>
            {imageUrl ? (
                <CardMedia
                    component="img"
                    image={imageUrl}
                    title="Uploaded Image"
                    sx={{
                        // Adjust height for larger preview
                        objectFit: 'cover',
                    }}
                />
            ) : (
                <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant="body2" color="textSecondary">
                        No Image
                    </Typography>
                </CardContent>
            )}
            <CardActions sx={{ justifyContent: 'space-between' }}>
                <TextField
                    source={source}
                    sx={{ mt: 1 }}
                />
            </CardActions>
        </Card>
    );
};


export const ProductShowContent = (props) => {
    let record = useRecordContext(props);
    if (!record) return null;

    return (
    <Grid container columnSpacing={2}>
        <Grid item xs={12} md={8} sm={8}>
            <Grid container columnSpacing={2}>
                <Grid item xs={12} sm={8}>
                    <Labeled label="Title">
                        <Typography variant="p">{record.title}</Typography>
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Labeled label="SKU">
                        <Typography variant="p">{record.sku}</Typography>
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Labeled label="Brand">
                        <Typography variant="p">{record.brand_id}</Typography>
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Labeled label="Part Type">
                        <Typography variant="p">{record.part_type}</Typography>
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Labeled label="tags">
                        <>
                            {record.tags.map((tag, index) => (<ChipField key={index} source="name" record={tag} />))}
                        </>
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Labeled label="Short Description">
                        <Typography variant="p">{record.short_description}</Typography>
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Labeled label="Long Description">
                        <Typography variant="p">{record.long_description}</Typography>
                    </Labeled>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h5">Images</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                {record.images.map((image, index) => (<Grid key={index} item xs={2}><ImageField source="url" record={image} sx={{ '&img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain'} }}/></Grid>))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={4} sm={4}>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Custom Fields</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400, marginBottom: "1%" }}>
                    <Grid container>
                        {record.custom_fields.map((field, index) => <React.Fragment key={index}><Grid item xs={4}>{field.name}</Grid><Grid item xs={8}>{field.value}</Grid></React.Fragment>)}
                    </Grid>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Shipping</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400, marginBottom: "1%" }}>
                        {record.shipping.map((shipping, index) => (
                                <Grid key={index} container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Labeled label="Shipping Type">
                                            <NumberField source="length" record={shipping} />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Labeled label="width">
                                            <NumberField source="width" record={shipping} />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Labeled label="height">
                                            <NumberField source="height" record={shipping} />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Labeled label="dimension_unit">
                                            <ChipField source="dimension_unit" record={shipping} />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Labeled label="weight">
                                            <NumberField source="weight" record={shipping} />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Labeled label="weight_unit">
                                            <ChipField source="weight_unit" record={shipping} />
                                        </Labeled>
                                    </Grid>
                                </Grid>)
                            )}
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Price</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400, marginBottom: "1%" }}>
                        {record.pricing.map((price, index) => (
                                <Grid key={index} container columnSpacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Labeled label="Price Type">
                                            <ChipField source="price_type" record={price} />
                                        </Labeled>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Labeled label="Price">
                                            <NumberField source="price" record={price} />
                                        </Labeled>
                                    </Grid>
                                </Grid>)
                            )
                        }
                    </Box>

                </AccordionDetails>
            </Accordion>
            {/* <Accordion sx={{ marginBottom: "3%" }} variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Categories</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ overflowY: 'auto', maxHeight: 400 }}>
                        {record.categories.map((category, index) => (
                            <ChipField key={index} source="name" record={category} />
                        ))}
                    </Box>
                </AccordionDetails>
            </Accordion> */}
        </Grid>
    </Grid>
);
};