import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';

import * as Sentry from "@sentry/react";

const environment = import.meta.env.DEV ? "dev" : "production";

// Sentry.init({
//   dsn: "https://9128ae14438624b75407f2030e3d9921@o4507170466496512.ingest.us.sentry.io/4507170530394112",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost"],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   environment: environment
// });

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
