import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    PasswordInput,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';

function MappingEdit() {
    return (
        <Edit>
            <SimpleForm>
                <TextInput source="name" />
                <TextInput source="type" validate={required()} />
                <ArrayInput source="mapped_fields">
                    <SimpleFormIterator inline>
                        <TextInput source="external_key" validate={required()} />
                        <TextInput source="internal_key" />
                        <TextInput source="type" />
                        <TextInput source="agg" />
                        <TextInput source="default" />
                        <TextInput source="filter.key" />
                        <TextInput source="filter.value" />
                        <TextInput source="str_format" />

                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
}

export default MappingEdit;
