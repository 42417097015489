export default function groupYears(years: number[]): string {
    if (years.length === 0) return '';

    // Sort the array
    years.sort((a, b) => a - b);

    let result: string[] = [];
    let start: number = years[0];
    let end: number = start;

    for (let i = 1; i < years.length; i++) {
        if (years[i] === end + 1) {
            // Continue the range
            end = years[i];
        } else {
            // End the current range and start a new one
            if (start === end) {
                result.push(`${start}`);
            } else {
                result.push(`${start}-${end}`);
            }
            start = years[i];
            end = start;
        }
    }

    // Add the last range
    if (start === end) {
        result.push(`${start}`);
    } else {
        result.push(`${start}-${end}`);
    }

    return result.join(', ');
}