import { Edit } from 'react-admin';
import FitmentForm from './Form';

export default function FitmentEdit() {
    return (
        <Edit>
            <FitmentForm />
        </Edit>
    );
};
