import {
    Edit,
    SimpleForm,
    TextInput,
    required,
    PasswordInput,
} from 'react-admin';

function BCEdit() {
    return (
        <Edit>
            <SimpleForm>
                <TextInput source="name" validate={required()} />
                <TextInput source="store_hash" validate={required()} />
                <PasswordInput source="access_token" validate={required()} />
            </SimpleForm>
        </Edit>
    );
}

export default BCEdit;
