import { fetchUtils } from 'react-admin';
import restProvider from './restProvider';
import fitment from './fitment';

const httpClient = (url: string, options: fetchUtils.Options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const { fullName, key, id } = JSON.parse(sessionStorage.getItem('auth') || '{}');
  if (key) {
  (options.headers as Headers).set('Authorization', key);
  }
  else {
    console.log('No key found');
  }
  return fetchUtils.fetchJson(url, options);
};

let apiUrl: string;

if (import.meta.env.DEV) {
  apiUrl = import.meta.env.VITE_API_URL_DEV;
} else {
  apiUrl = import.meta.env.VITE_API_URL_PROD;
}

const baseDataProvider = restProvider(apiUrl, httpClient);
const dataProvider = {
  ...baseDataProvider,
  syncSema: (credentialsID: number) => {
    return httpClient(`${apiUrl}/sema/brand-list/${credentialsID}`, { method: 'GET' }).then(response => {response.json});
  },
  downloadSema: (brandID: number) => {
    return httpClient(`${apiUrl}/sema/brands/download/${brandID}`, { method: 'GET' }).then(response => {response.json});
  },
  downloadFitment: (brandID: number) => {
    return httpClient(`${apiUrl}/fits/import/${brandID}`, { method: 'GET' }).then(response => {response.json});
  },
  applyRule: (id: number) => {
    return httpClient(`${apiUrl}/rules/apply/${id}`, { method: 'GET' }).then(response => {response.json});
  },
  applyBulkRules: (ids: number[]) => {
    return httpClient(`${apiUrl}/rules/apply`, { method: 'POST', body: JSON.stringify(ids) }).then(response => {response.json});
  },
  bigcommercePush: (id: number) => {
    return httpClient(`${apiUrl}/bigcommerce/push/${id}`, { method: 'GET' }).then(response => {response.json});
  },
  fitmentUpdate: (ids: number[]) => {
    return httpClient(`${apiUrl}/fit-conflicts/update`, { method: 'POST', body: JSON.stringify(ids) }).then(response => {response.json});
  },
  fitmentUpdateAll: (id: number, how: "all" | "product" | "action" | "reject_product" | "reject_action" | "always_product" | "always_action") => {
    return httpClient(`${apiUrl}/fit-conflicts/update-all/${id}`, { method: 'POST', body: JSON.stringify({"how": how}) }).then(response => {response.json});
  },
  productUpdate: (id: number, how: "accept" | "reject" | "accept_path" | "reject_path" | "accept_product" | "reject_product" | "reject_all") => {
    return httpClient(`${apiUrl}/updates/resolve/${id}`, { method: 'POST', body: JSON.stringify({"how": how}) }).then(response => {response.json});
  }
};

export default dataProvider;