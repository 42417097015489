import {
    BooleanField,
    Button,
    Datagrid,
    FormDataConsumer,
    ImageField,
    Labeled,
    Pagination,
    ReferenceManyCount,
    ReferenceManyField,
    Show,
    TabbedShowLayout,
    TextField,
} from 'react-admin';
import { DownloadButton } from './downloadButton';
import { FitmentButton } from './fitmentButton';

function BrandShow() {
    return (
        <Show>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Brand">
                    <ImageField source="logo_url" label="Logo" />
                    <TextField source="aaia_id" label="Brand Code" />
                    <TextField source="brand_name" label="Brand Name" />
                    <BooleanField source="downloaded" label="Downloaded" />
                    <DownloadButton />
                    <FitmentButton />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Products" path="products" count={<ReferenceManyCount reference='new-products' target='brand_id' sx={{ lineHeight: 'inherit' }} /> } >
                    <ReferenceManyField reference="new-products" target="brand_id" source='id' label={false} pagination={<Pagination />} >
                        <Datagrid rowClick="edit">
                            <TextField source="sku" label="SKU" />
                            <TextField source="title" />
                        </Datagrid>
                    </ReferenceManyField>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    );
}

export default BrandShow;
