import NProductList from "./List";
import NProductEdit from "./Edit";
import NProductCreate from "./Create";
import { Category } from '@mui/icons-material';

export default {
    list: NProductList,
    edit: NProductEdit,
    create: NProductCreate,
    recordRepresentation: "title",
    icon: Category
};