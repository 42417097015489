import {
    ArrayInput,
    CreateButton,
    DatagridConfigurable,
    List,
    SearchInput,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TopToolbar,
} from 'react-admin';

function MappingList() {
    return (
        <List
            // sort={{ field: 'published_at', order: 'DESC' }}
            filters={[<SearchInput source="q" alwaysOn key="q" />]}
            actions={
                <TopToolbar>
                    <CreateButton />
                </TopToolbar>
            }
        >
            <DatagridConfigurable rowClick="edit">
                <TextField source="name" />
                <TextField source="type" />

            </DatagridConfigurable>
        </List>
    );
}

export default MappingList;
