// in src/Settings.js
import * as React from "react";
import { Card, CardContent, Grid, Typography, Divider, TableHead } from '@mui/material';
import { Title, List, SimpleForm, SimpleList, Button, TextField, Datagrid, Labeled } from 'react-admin';
import { SyncButton } from "../sema/syncButton";
import { PushButton } from "../bigcommerce/pushButton";

const Settings = () => (
    <Card>
        <Title title="Settings" />
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                <Card variant="outlined">
                    <CardContent>
                    <Typography variant="h6">API Tokens</Typography>
                    <List resource="api-tokens" exporter={false} pagination={false}>
                        <SimpleList primaryText={record => record.token} />
                    </List>
                    </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Card variant="outlined">
                        <CardContent>
                            <Typography variant="h6">SEMA Credentials</Typography>
                            <List resource="sema-connection" exporter={false} pagination={false}>
                                <Datagrid bulkActionButtons={false} >
                                    <TextField source="username" />
                                    <Labeled label="Sync">
                                        <Labeled label={false}>
                                            <SyncButton />
                                        </Labeled>
                                    </Labeled>
                                </Datagrid>
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={4}>
                <Card variant="outlined">
                    <CardContent>
                    <Typography variant="h6">BigCommerce Credentials</Typography>
                    <List resource="bigcommerce-connection" exporter={false} pagination={false} empty={false}>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="name" />
                            <Labeled label="Push">
                                <Labeled label={false}>
                                    <PushButton />
                                </Labeled>
                            </Labeled>
                        </Datagrid>
                    </List>
                    </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

export default Settings;